import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { startCase, capitalize } from 'lodash';

import Layout from '../components/layout';
import Head from '../components/head';

import styles from './listpage.module.scss';

const ColoursPage = ({
  data: {
    allContentfulVehicle: { nodes: allVehicles },
  },
  pageContext: { colour: colourFilter, ordering },
}) => {
  let orderedVehicles = [];

  let inOrder = [];

  ordering.forEach((thisPlate, i) => {
    const match = allVehicles.filter(
      ({ numberPlate }) => thisPlate === numberPlate
    );

    if (match) {
      inOrder.push(...match);
    }

    if (i + 1 >= ordering.length) {
      orderedVehicles = inOrder.filter(
        ({ colour }) => colour.trim().toLowerCase() === colourFilter
      );
    }
  });

  return (
    <Layout>
      <Head
        title={`${startCase(colourFilter)} Vehicles`}
        description={`A gallery of ${capitalize(
          colourFilter
        )} vehicles parked in Hollingdean, blocking local residents from being able to park on their own streets.`}
        noIndex={true}
      />

      <main className={styles.home}>
        <h2>{capitalize(colourFilter)} vehicles in Hollingdean</h2>
        <div className={styles.content}>
          <p>
            Here, you will find only {colourFilter} vehicles, all causing
            additional congestion, noise, and pollution to the residents of
            Hollingdean.
          </p>
        </div>

        <ul className={styles.grid}>
          {orderedVehicles.map(
            (
              {
                make,
                model,
                colour,
                numberPlate,
                gridImage: {
                  localFile: {
                    childImageSharp: { gatsbyImageData },
                  },
                },
              },
              i
            ) => (
              <li key={`allVehicles-${i}`} className={styles.item}>
                <Link
                  to={`/gallery/${numberPlate
                    .replace(' ', '-')
                    .toLowerCase()}/`}
                  className={styles.link}
                  aria-label={`View ${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                >
                  <GatsbyImage
                    image={gatsbyImageData}
                    alt={`${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                    placholder="dominantColor"
                    loading="lazy"
                  />
                  <h2 className={styles.numberplate}>{numberPlate}</h2>
                </Link>
              </li>
            )
          )}
        </ul>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query ColoursPage {
    allContentfulVehicle {
      nodes {
        id
        numberPlate
        colour
        make
        model
        gridImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                quality: 40
                placeholder: DOMINANT_COLOR
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`;

export default ColoursPage;
